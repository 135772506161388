import currencyFormat from './currencyFormat';

const templateFunctions = {
  methods: {
    currencyFormat,
    isOdd: (num: number) => num % 2,
  },
};

export default templateFunctions;
